<template>
  <!-- TODO: Replace headerButtons with a button bar component that takes md-buttons as a child (maybe in the future when md-components is a considered project?) -->
  <md-report-layout :header-buttons="headerButtons"
                    :loading="loading"
                    :loading-text="loadingText"
                    ref="reportLayout">

    <template v-slot:header-left>
      <md-date-select :init-start="rangeStart" :init-end="rangeEnd" @change="dateRangeChanged" display-mode="header"/>
    </template>

    <template v-slot:employee-list>
      <!--md-date-select :init-start="rangeStart" :init-end="rangeEnd" @change="dateRangeChanged"/-->

      <md-employee-list :loading="loadingEmployees"
                        :employees="employees"
                        :can-select-multiple="true"
                        :init-all-selected="true"
                        @employeeSelected="onEmployeeSelected"
                        style="height: calc(100vh - 400px); margin-bottom: 10px;"/>

      <div class="header" style="font-size: 13px; padding-left: 5px; font-weight:bold; margin-bottom: 10px; text-align: left">
        {{ countSelectedEmployees }} von {{ countEmployees }} Mitarbeiter sind ausgewählt
      </div>

      <div class="header" style="display:flex; flex-direction: row; justify-content: flex-start;">
        <b-button size="sm"
                  variant="outline-secondary"
                  v-on:click="selectAllEmployees"
                  style="border-radius: 0; margin-right: 0.5rem;">
          alle auswählen
        </b-button>
        <b-button size="sm"
                  variant="outline-secondary"
                  v-on:click="deselectAllEmployees"
                  style="border-radius: 0; margin-right: 1rem; justify-self: flex-start;">
          alle abwählen
        </b-button>

        <b-button size="sm"
                  variant="outline-secondary"
                  v-on:click="toggleShowOnlyActiveEmployees"
                  style="border-radius: 0; margin-left: auto;">
          {{ showOnlyActiveEmployees ? 'alle Mitarbeiter anzeigen' : 'nur aktive Mitarbeiter anzeigen' }}
        </b-button>
      </div>
    </template>

    <template v-slot:report-settings>
      <md-report-config :options="reportConfigData" @configChanged="onReportConfigChange"/>
    </template>

    <template v-slot:report-list>
      <ml-report-list :reports="reports"
                      :loading="loadingReports"
                      @deleteReport="openDeleteReportPopup"
                      @downloadReport="downloadReport"
                      @reportClicked="openReport"/>
    </template>

    <template v-slot:button-bar v-if="creatingReport || !canCreateReport">
      <span v-if="creatingReport">
        <i class="fas fa-circle-notch fa-spin"></i>
        Report wird erstellt
      </span>
      <span v-else-if="!storeSelected">
        Bitte mindestens einen Store auswählen
      </span>
      <span v-else-if="customRangeUsed">
        Dynamische Zeiträume werden für diesen Report nicht unterstützt
      </span>
      <span v-else>
        Bitte mindestens einen Mitarbeiter auswählen
      </span>
    </template>

  </md-report-layout>
</template>

<script>
import moment from 'moment';
import MdReportLayout from '@/components/reportcontext/ReportLayout';
import MdDateSelect from '@/components/uicomponents/md-date-select';
import MlReportList from '@/components/uicomponents/ml-report-list';
import { reportMixin } from '@/components/reportcontext/report-mixin';
import { mapGetters } from "vuex";
import VueEventBus from '../../vue-event-bus';

export default {
  name: 'reportbmdexportsums',
  components: {
    MdReportLayout,
    MdDateSelect,
    MlReportList,
  },
  mixins: [ reportMixin ],
  data() {
    return {
      reportType: 'BMDEXPORTSUMS',
      loadingText: 'Report Bmd Export Summen wird geladen',

      // models: [],
      // individualWageTypes: [],
      mdWageTypes: [],
      contracts: [],
    }
  },
  created: function () {
    // console.log("CREATED");
    // VueEventBus.$on("JobReportEvent:jobCreated", this.jobCreated);
    // VueEventBus.$on("JobReportEvent:jobStarted", this.jobStarted);
    // VueEventBus.$on("JobReportEvent:jobEnded", this.jobEnded);
    // VueEventBus.$on("JobReportEvent:jobFailed", this.jobFailed);
  },
  beforeDestroy: function () {
    // console.log("BEFORE DESTROY");
    // VueEventBus.$off("JobReportEvent:jobCreated", this.jobCreated);
    // VueEventBus.$off("JobReportEvent:jobStarted", this.jobStarted);
    // VueEventBus.$off("JobReportEvent:jobEnded", this.jobEnded);
    // VueEventBus.$off("JobReportEvent:jobFailed", this.jobFailed);
  },
  destroyed: function () {
    // console.log("DESTROYED");
  },
  mounted() {
    if (this.$store.state.jwt !== undefined && this.$store.state.companyId !== undefined) {

      this.refresh();
    }
  },
  computed: {
    ...mapGetters(['companyId','companySettings', 'storesSettings','employeeContractMap']),
    headerButtons() {
      let helperLinks = [];
      let buttons = [
        {
          classes: 'add-employeetype',
          type: 'block gray',
          disabled: false,
          icon: 'fa-th-list',
          click: ($event) => {
          },
          show: true,
          tooltip: 'Bmd Export Summen',
          title: 'Bmd Export Summen',
          helperText: 'Der Report Bmd Export Summen stellt Summen für ausgewählte Monatssummen und Abwesenheiten dar.',
          //externalUrl: "https://meindienstplan.atlassian.net/servicedesk/customer/kb/view/1134428161"
        },
        {
          type: 'block green',
          disabled: this.creatingReport || !this.canCreateReport,
          icon: this.creatingReport ? 'fa-redo' : 'fa-file-check',
          click: ($event) => {
            this.createReportProxy();
          },
          show: true,
          tooltip: 'Report erstellen',
          title: 'Report erstellen',
        },
      ];
      let state = {
        buttons: buttons,
        helperLinks: helperLinks,
        showListIconAlways: true,
      };

      state.helperLinks = [
        {
            icon: "fa-video",
            title: "Schulungsvideo ansehen",
            helperText: "Sieh dir das Video der Basisschulung zu diesem Bereich an um nochmal die Erklärung zu allen grundlegenden Funktionen zu sehen.",
            externalUrl: "https://www.youtube.com/watch_popup?v=BePh4xNJvsY",
            externalLabel: "Basisschulung 'Reports'",
            cancelLabel: "Schließen"
        },
        {
          title: 'Support erhalten',
          helperText: 'Wenn Sie auf Ihre Frage keine Antwort im Hilfecenter finden, können Sie uns eine Support Anfrage stellen. Klicken Sie dazu einfach auf \'Support kontaktieren\', geben Sie Ihre E-Mail Adresse ein, schreiben Sie eine detaillierte Zusammenfassung Ihres Problems inkl. der Daten Ihres Stores, laden Sie etwaige Anhänge (Bilder, etc.) hoch und klicken Sie auf \'Senden\'. Wir sehen uns Ihr Problem an und melden uns so schnell wie möglich.',
          externalUrl: 'https://meindienstplan.atlassian.net/servicedesk/customer/portal/1/group/2/create/15',
          externalLabel: 'Support kontaktieren',
          cancelLabel: 'Schließen',
        },
      ];
      return state;
    },

    canCreateReport() {
      return this.storeSelected && this.employeesSelected && !this.customRangeUsed;
    },
    // employmentModelsSelected() {
    //   const employmentModels = this.reportConfig.employmentModels || {};
    //   return (employmentModels.selectedModels || []).length > 0;
    // },
    // wageTypesSelected() {
    //   const individualWageTypes = this.reportConfig.individualWageTypes || {};
    //   return (individualWageTypes.selectedWageTypes || []).length > 0;
    // },
    storeSelected() {
      const storeIds = this.reportConfig.storeIds || {};
      return (storeIds.selectedStores || []).length > 0;
    },
    employeesSelected() {
      return this.countSelectedEmployees > 0;
    },
    customRangeUsed() {
      return this.rangeMode === 'customRange';
    },
  },
  watch: {
    companyId(newVal, oldVal) {
      console.log("COMPANY ID CHANGED", newVal, oldVal);
      this.refresh(true);
    },
    rangeStart() {
      this.updateReportConfig('reportname', this.getReportName());
    },
  },
  // methods that implement data (BUSINESS) logic. (https://en.wikipedia.org/wiki/Business_logic)
  methods: {
    getReportName() {
      return this.rangeMode === 'monthlyRange'
          ? `Bmd Export Summen, ${ moment(this.rangeStart).format('MMMM YYYY') }`
          : `Bmd Export Summen, ${ moment(this.rangeStart).format('DD.MM.YYYY') } bis ${ moment(this.rangeEnd).format('DD.MM.YYYY') }`;
    },

    async refresh(force = false) {
      this.loading = true;
      this.updateReportConfig('reportname', this.getReportName());

      try {
        await Promise.all([
          this.$helpers.GetCompanySettings(force),
          this.$helpers.GetStoresSettings(force),
          this.$helpers.GetExportSettingsMap(force),
          this.$helpers.GetEmployeeContractMap(force),
          this.getEmployeeContracts(),
          this.loadMdWageTypes(),
          // this.loadModels(),
          // this.loadIndividualWageTypes(),
          this.loadReports(this.reportType),
          this.loadEmployees(true),
        ]);
      } catch (error) {
        console.error(error);
      } finally {
        this.generateReportConfigOpts();

        // console.log(this.getReportConfigValue('reportname'));
        // reportConfig can be loaded after generating the options, as reportConfig does not overwrite the options
        this.reportConfig = this.loadReportConfig();
        this.updateReportConfig('reportname', this.getReportName());

        // console.log(this.reports);

        this.loading = false;
      }
    },

    generateReportConfigOpts() {
      let view = this;
      let disabled = !this.CheckEnableConfigEdit
      let specialDisabled = !this.CheckEnableConfigSpecialEdit
      const nameSection = this.createReportConfigSection('Dokumentname', true);
      this.addTextConfigOption(nameSection, 'reportname', 'Dokumentname eingeben', this.getReportName(), true);

      // const commentSection = this.createReportConfigSection('Kommentar');
      // this.addTextConfigOption(commentSection, 'reportcomment', 'Kommentar eingeben');


      const formatSection = this.createReportConfigSection('Dateiformat', false, disabled);
      this.addSelectConfigOption(formatSection, 'fileformat', [
        this.addSelectableValue('PDF', 'pdf', true),
        // this.addSelectableValue('CSV', 'csv'),
      ]);


      const storeSection = this.createReportConfigSection('Store', false, disabled);
      view.addSelectConfigOption(storeSection, 'storeIds', [
        view.addSelectableValue('Alle Stores',
          this.storesSettings.data.map(store => view.addSelectableValue(store.name, store.id)),
          this.storesSettings.data.map(store => store.id),
          'selectedStores')
      ], true);

      const contentSection = this.createReportConfigSection('Dokumentinhalt', false, disabled);
      view.addSelectConfigOption(contentSection, 'monthlyWageTypes', [
        view.addSelectableValue('Alle Monatssummen',
          view.mdWageTypes.filter(wageType => wageType.monthly).sort((a,b) => (a.isMain != b.isMain ? (a.isMain ? -1 : 1) : 0)).map(wageType => view.addSelectableValue(wageType.name, wageType.id)),
          view.mdWageTypes.filter(wageType => wageType.monthly).sort((a,b) => (a.isMain != b.isMain ? (a.isMain ? -1 : 1) : 0)).map(wageType => wageType.id),
          'selectedMonthlyWageTypes')
      ], true);
      view.addSelectConfigOption(contentSection, 'dailyWageTypes', [
        view.addSelectableValue('Alle Abwesenheiten',
          view.mdWageTypes.filter(wageType => !wageType.monthly).sort((a,b) => (a.isMain != b.isMain ? (a.isMain ? -1 : 1) : 0)).map(wageType => view.addSelectableValue(wageType.name, wageType.id)),
          view.mdWageTypes.filter(wageType => !wageType.monthly).sort((a,b) => (a.isMain != b.isMain ? (a.isMain ? -1 : 1) : 0)).map(wageType => wageType.id),
          'selectedDailyWageTypes')
      ], true);
      // view.addSelectConfigOption(contentSection, 'employeeSelection', [
      //   view.addSelectableValue('Alle Mitarbeiter', 1, true),
      //   view.addSelectableValue('Nur Mitarbeiter mit ausgewählten Werten', 3),
      // ]);
      // view.addSelectConfigOption(contentSection, 'employmentModels', [
      //   view.addSelectableValue('Alle Beschäftigungsgruppen',
      //     view.models.map(model => view.addSelectableValue(model.name, model.id)),
      //     view.models.map(model => model.id),
      //     'selectedModels')
      // ], true);
      // view.addSelectConfigOption(contentSection, 'individualWageTypes', [
      //   view.addSelectableValue('Alle Lohnarten',
      //     view.individualWageTypes.map(wageType => view.addSelectableValue(wageType.name, wageType.id)),
      //     view.individualWageTypes.map(wageType => wageType.id),
      //     'selectedWageTypes')
      // ], true);
      // view.addSelectConfigOption(contentSection, 'showOnlySums', [
      //   view.addSelectableValue('Alle Werte anzeigen', false, true),
      //   view.addSelectableValue('Nur Summen anzeigen', true),
      // ]);
      // view.addSelectConfigOption(contentSection, 'sums', [
      //   view.addSelectableValue('Alle Summen', [
      //       view.addSelectableValue('Mitarbeiter Summen', 'employeeSums', true),
      //       view.addSelectableValue('Beschäftigungsgruppe Summen', 'employmentModelSums', true),
      //       view.addSelectableValue('Lohnart Summen', 'wageTypeSums', true),
      //       view.addSelectableValue('Gesamt Summen', 'totalSums', true),
      //     ],
      //     ['employeeSums', 'employmentModelSums', 'wageTypeSums', 'totalSums'],
      //     'selectedSums')
      // ], true);

      // const groupSection = this.createReportConfigSection('Gruppierung', false, specialDisabled);
      // this.addSelectConfigOption(groupSection, 'groupBy', [
      //   this.addSelectableValue('Mitarbeiter nicht gruppieren', 0, true),
      //   this.addSelectableValue('Mitarbeiter nach Beschäftigungsgruppe gruppieren', 1),
      // ]);

      // const sortSection = this.createReportConfigSection('Sortierung', false, specialDisabled);
      // this.addSelectConfigOption(sortSection, 'sortEmployeesBy', [
      //   this.addSelectableValue('Mitarbeiter nach Nachnamen sortieren', 0, true),
      //   this.addSelectableValue('Mitarbeiter nach Personalnummer sortieren', 1),
      // ]);
      // this.addSelectConfigOption(sortSection, 'sortEntriesBy', [
      //   this.addSelectableValue('Einzelposten nach EarnCode sortieren', 0, true),
      //   this.addSelectableValue('Einzelposten nach Datum sortieren', 1),
      // ]);



      const headerFormatSection = this.createReportConfigSection('Kopfzeile', false, specialDisabled);
      this.addSelectConfigOption(headerFormatSection, 'settingDateFormat', [
        this.addSelectableValue('DD.MM.YYYY', 'DD.MM.YYYY', true),
        this.addSelectableValue('YYYY-MM-DD', 'YYYY-MM-DD'),
      ]);

    },

    async createReportProxy() {
      if (this.rangeMode === 'customRange') {

        if (this.rangeStart.month() != this.rangeEnd.month() || this.rangeStart.year() != this.rangeEnd.year()) {
          this.$helpers.error('Fehler', 'Start und Enddatum müssen im selben Monat liegen!');
          return;
        }
        if (this.rangeStart > this.rangeEnd) {
          this.$helpers.error('Fehler', 'Startdatum muss vor Enddatum liegen!');
          return;
        }

      }
      await this.generateReport();
    },


    getEmployeeContracts: function () {
      let view = this;
      let url = '/api/sec/proxy/md/employee/contracts';

      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, '/api/sec/proxy/md/employee/contracts');

      return this.axios({
        method: 'get',
        headers: {
          'AUTHORIZATION': "Bearer " + this.$store.state.jwt
        },
        url: url
      }).then(resp => {
        return view.$helpers.GetStores()
          .then(storeResponse => {
            let storeIds = storeResponse.map(s => s.id);
            view.contracts = {};
            Object.values(resp.data).forEach(contract => {
              if (storeIds.includes(contract.storeId)) {
                if (view.contracts.hasOwnProperty(contract.remoteId)) {
                  view.contracts[contract.remoteId].push(contract);
                } else {
                  view.contracts[contract.remoteId] = [];
                  view.contracts[contract.remoteId].push(contract);
                }
              }
            });
            // console.log(view.contracts);
          });
      }).catch(error => {
        console.log(error);
      });
    },


    async generateReport() {
      this.creatingReport = true;

      const employeeIds = this.selectedEmployeeIds;

      const dateFormats = {
        'DD.MM.YYYY': 'dmY',
        'YYYY-MM-DD': 'Ymd',
      };
      const dateFormat = dateFormats[this.reportConfig.settingDateFormat] || 'dmY';

      const hourFormats = {
        '3': '3',
        '2': '2',
        'Hm': 'Hm',
      };
      const hourFormat = hourFormats[this.reportConfig.settingHourFormat] || 'Hm';

      const name = this.reportConfig.reportname;
      // const comment = this.reportConfig.reportcomment;
      const config = {
        'year': this.rangeStart.year(),
        'month': this.rangeStart.month() + 1,
        'headerDateFormat': dateFormat,
        'hourFormat': hourFormat,
        'storeIds': this.reportConfig.storeIds.selectedStores,
        'monthlyWageTypes': this.reportConfig.monthlyWageTypes.selectedMonthlyWageTypes,
        'dailyWageTypes': this.reportConfig.dailyWageTypes.selectedDailyWageTypes,
        // 'employmentModelsBool': false,
        // 'employmentModels': this.reportConfig.employmentModels.selectedModels,
        // 'wageTypesBool': false,
        // 'individualWageTypes': this.reportConfig.individualWageTypes.selectedWageTypes,
        // 'employeeSelection': this.reportConfig.employeeSelection,
        // 'showOnlySums': this.reportConfig.showOnlySums,
        // 'employeeSums': this.reportConfig.sums.selectedSums.includes('employeeSums'),
        // 'employmentModelSums': this.reportConfig.sums.selectedSums.includes('employmentModelSums'),
        // 'wageTypeSums': this.reportConfig.sums.selectedSums.includes('wageTypeSums'),
        // 'totalSums': this.reportConfig.sums.selectedSums.includes('totalSums'),
        // 'groupBy': this.reportConfig.groupBy,
        // 'sortEmployeesBy': this.reportConfig.sortEmployeesBy,
        // 'sortEntriesBy': this.reportConfig.sortEntriesBy,
        'headerLogo': 'ml',
        'fileformat': this.reportConfig.fileformat ?? 'pdf'
      };

      if (this.rangeMode === 'customRange') {
        config.fromDate = this.rangeStart.format();
        config.toDate = this.rangeEnd.format();
      }

      // console.log(employeeIds);
      // console.log(config);

      // store started report in DB
      let reportId = 0;
      let exportEntries = {
        "sum": {}
      };
      config.storeIds.forEach(storeId => {
        exportEntries[storeId] = {};
      });
      this.storeStartedReport(this.reportType, name, "", config)
      .then((response) => {
        // get reportId
        reportId = response.data.data.id;

        // get the export data
        let monthlyPayload = this.prepareMonthlyPayload(config);
        // console.log("monthlyPayload", monthlyPayload);
        this.getCsvExportFromBackend("monthly", monthlyPayload)
          .then((monthlyResponse) => {
            let monthlyCSV = this.$helpers.b64DecodeUnicode(monthlyResponse.data.data.exportCsv);
            // console.log("monthlyCSV", monthlyCSV);

            var monthlyHeaders = Object.entries(monthlyPayload.exportSettings.availableFields).filter(([key, item]) => item.checked).map(([key, item]) => item.key);

            var lines = monthlyCSV.split("\n");
            var hasHeader = monthlyPayload.exportSettings.availableFieldsAdditional.find(i => i.key == "headerAdditional").item.key === 1;
            for(var i = hasHeader ? 1 : 0; i < lines.length; i++) {
              var data = lines[i].split(';');
              if (data.length < monthlyHeaders.length) {
                continue;
              }
              var obj = {};
              for(var j = 0; j < data.length-1; j++) {
                if (monthlyHeaders[j]) {
                  let trimmed = data[j].trim();
                  if (["paidWorkTime", "dailyPayoutValue", "dailyPayoutHoursValue"].includes(monthlyHeaders[j].trim())) {
                    trimmed = parseFloat(trimmed.replace(",", "."));
                  }
                  obj[monthlyHeaders[j].trim()] = trimmed;
                }
              }

              // console.log(obj);
              config.storeIds.forEach(storeId => {
                if (!exportEntries[storeId].hasOwnProperty(obj.contentName)) {
                  exportEntries[storeId][obj.contentName] = {
                    "lohnart": obj.lohnart,
                    "paidWorkTime": 0,
                  };
                }
              });
              if (!exportEntries["sum"].hasOwnProperty(obj.contentName)) {
                exportEntries["sum"][obj.contentName] = {
                  "lohnart": obj.lohnart,
                  "paidWorkTime": 0,
                };
              }

              if (employeeIds.includes(parseInt(obj.remoteId))) {
                exportEntries[obj.storeId][obj.contentName].paidWorkTime += obj.paidWorkTime;
                exportEntries["sum"][obj.contentName].paidWorkTime += obj.paidWorkTime;
              }
            }

            let dailyPayload = this.prepareDailyPayload(config);
            // console.log("dailyPayload", dailyPayload);
            this.getCsvExportFromBackend("daily", dailyPayload)
              .then((dailyResponse) => {
                let dailyCSV = this.$helpers.b64DecodeUnicode(dailyResponse.data.data.exportCsv);
                // console.log("dailyCSV", dailyCSV);

                var dailyHeaders = Object.entries(dailyPayload.exportSettings.availableFields).filter(([key, item]) => item.checked).map(([key, item]) => item.key);
                var lines = dailyCSV.split("\n");
                var hasHeader = dailyPayload.exportSettings.availableFieldsAdditional.find(i => i.key == "headerAdditional").item.key === 1;
                for(var i = hasHeader ? 1 : 0; i < lines.length; i++) {
                  var data = lines[i].split(';');
                  if (data.length < dailyHeaders.length) {
                    continue;
                  }
                  var obj = {};
                  for(var j = 0; j < data.length-1; j++) {
                    if (dailyHeaders[j]) {
                      let trimmed = data[j].trim();
                      if (["paidWorkTime", "dailyPayoutValue", "dailyPayoutHoursValue"].includes(dailyHeaders[j].trim())) {
                        trimmed = parseFloat(trimmed.replace(",", "."));
                      }
                      obj[dailyHeaders[j].trim()] = trimmed;
                    }
                  }

                  // console.log(obj);
                  // create empty absence entries for all stores
                  config.storeIds.forEach(storeId => {
                    if (!exportEntries[storeId].hasOwnProperty(obj.dailyContentName)) {
                      exportEntries[storeId][obj.dailyContentName] = {
                        "dailyLohnart": obj.dailyLohnart,
                        "dailyPayoutValue": 0,
                        "dailyPayoutHoursValue": 0,
                      };
                    }
                  });
                  if (!exportEntries["sum"].hasOwnProperty(obj.dailyContentName)) {
                    exportEntries["sum"][obj.dailyContentName] = {
                      "dailyLohnart": obj.dailyLohnart,
                      "dailyPayoutValue": 0,
                      "dailyPayoutHoursValue": 0,
                    };
                  }

                  if (employeeIds.includes(parseInt(obj.remoteId))) {
                    // console.log(obj);
                    if (obj.hasOwnProperty('storeId') && config.storeIds.includes(parseInt(obj.storeId))) {
                      exportEntries[obj.storeId][obj.dailyContentName].dailyPayoutValue += obj.dailyPayoutValue;
                      exportEntries[obj.storeId][obj.dailyContentName].dailyPayoutHoursValue += obj.dailyPayoutHoursValue;
                      exportEntries["sum"][obj.dailyContentName].dailyPayoutValue += obj.dailyPayoutValue;
                      exportEntries["sum"][obj.dailyContentName].dailyPayoutHoursValue += obj.dailyPayoutHoursValue;
                    }
                  }
                }

                // console.log(exportEntries);

                // generate pdf
                let [doc, filename] = this.$helpers.generateBmdExportSumsPDF(exportEntries, this.storesSettings.data, config.month, config.year, this.userFullname);
                let blob = doc.output('blob');

                // store pdf in DB
                this.storeFinishedReport(this.reportType, reportId, new File([blob], filename));
                this.creatingReport = false;
              })
              .catch((error) => {
                console.error(error);
                this.creatingReport = false;
              });
          })
          .catch((error) => {
            console.error(error);
            this.creatingReport = false;
          });

      })
      .catch((error) => {
        console.error(error);
        this.creatingReport = false;
      });
    },
    jobCreated: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB CREATED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 50);
    },
    jobStarted: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB STARTED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 100);
    },
    jobEnded: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB ENDED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 200);
    },
    jobFailed: function () {
      let view = this;
      setTimeout(function () {
        console.log("JOB FAILED EVENT GOT TRIGGERED");
        view.reloadDocuments();
      }, 200);
    },
    reloadDocuments: function () {
      this.loadReports(this.reportType);
    },


    // these won't be needed for now
    loadModels: function () {
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      return view.$helpers
      .GetCall("/api/sec/employmentModels", params)
      .then((response) => {
        let data = response.data.data;
        let tmp = [];
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            number: data[i].number,
            name: data[i].name,
          };
          tmp.push(item);
        }
        view.models = tmp.slice();
        console.log("models", view.models);
      })
      .catch((e) => {
        console.log(e);
      });
    },
    loadIndividualWageTypes: function () {
      let view = this;
      let params = { companyId: view.$store.state.companyId };
      return view.$helpers
      .GetCall("/api/sec/individualWageTypes", params)
      .then((response) => {
        let data = response.data.data;
        let tmp = [];
        for (let i = 0; i < data.length; i++) {
          let item = {
            id: data[i].id,
            numberString: data[i].numberString,
            name: data[i].name,
            details: data[i].details,
          };
          tmp.push(item);
        }
        view.individualWageTypes = tmp.slice();
        console.log("individualWageTypes", view.individualWageTypes);
      })
      .catch((e) => {
        console.log(e);
      });
    },
    loadMdWageTypes: function () {
      let view = this;
      return view.$helpers
      .GetCall("/api/sec/wagetypes", {})
      .then((response) => {
        console.log(response);
        view.mdWageTypes = [];

        for (let i = 0; i < response.data.length; i++) {
          // 0 is causing an issue with the select component
          response.data[i].id = response.data[i].id == 0 ? 1000 : response.data[i].id;
          response.data[i].enumKey = response.data[i].id;
        }
        view.mdWageTypes = response.data;
        console.log("mdWageTypes", view.mdWageTypes);
      })
      .catch((e) => {
        console.log(e);
      });
    },

    prepareMonthlyPayload(config) {
      let payload = {};
      payload.year = '' + config.year;
      payload.month = config.month < 10 ? '0' + config.month : '' + config.month;
      payload.exportSettings = {};
      payload.exportSettings.selectedWageTypes = config.monthlyWageTypes;
      payload.exportSettings.selectedStores = config.storeIds;
      payload.exportSettings.id = 0;
      payload.exportSettings.name = "Standard";
      payload.exportSettings.availableFields = [
          {key: "externalCompanyId", name: "Betrieb ID", newName: "Firma", checked: true},
          {key: "externalEmployeeId", name: "Mitarbeiter ID", newName: "Mitarbeiternummer", checked: true},
          {key: "month", name: "Monat", newName: "Monat", checked: true},
          {key: "rollUpMonth", name: "Aufrollmonat", newName: "Aufrollmonat", checked: true},
          {key: "lohnart", name: "Lohnart", newName: "Lohnart", checked: true},
          {key: "paidWorkTime", name: "Ausbezahlter Wert", newName: "Ausbezahlter Wert", checked: true},
          {key: "contentName", name: "Inhalt Bezeichnung", newName: "Inhalt", checked: true},
          {key: "employeeName", name: "Mitarbeiter Name", newName: "Name", checked: true},
          {key: "storeId", name: "storeId", newName: "storeId", checked: true},
          {key: "remoteId", name: "remoteId", newName: "remoteId", checked: true}
      ];
      payload.exportSettings.availableFieldsAdditional = [
          {
              "key": "headerAdditional",
              "name": "Listenaufbau",
              "item": {
                  "key": 0,
                  "label": "Spaltenbezeichnungen nicht andrucken"
              },
              "checked": true
          },
          {
              "key": "monthAdditional",
              "name": "Monat",
              "item": {
                  "key": "current",
                  "label": "Aktueller Monat",
                  "selected": true
              },
              "checked": true
          },
          {
              "key": "rollUpMonthAdditional",
              "name": "Aufrollmonat",
              "item": {
                  "key": "current",
                  "label": "Aktueller Monat"
              },
              "checked": true
          },
          {
              "key": "paidWorkTimeAdditional",
              "name": "Ausbezahlter Wert",
              "item": {
                  "key": 0,
                  "label": "Null Werte exportieren",
                  "selected": true
              },
              "checked": true
          },
          {
              "key": "donhauserGroup",
              "use": false,
          }
      ];
      return payload;
    },
    prepareDailyPayload(config) {
      let payload = {};
      payload.year = '' + config.year;
      payload.month = config.month < 10 ? '0' + config.month : '' + config.month;
      payload.exportSettings = {};
      payload.exportSettings.selectedWageTypes = config.dailyWageTypes;
      payload.exportSettings.selectedStores = config.storeIds;
      payload.exportSettings.id = 0;
      payload.exportSettings.name = "Standard";
      payload.exportSettings.availableFields = [
          {key: "dailyExternalCompanyId", name: "Betrieb ID", newName: "Firma", checked: true},
          {key: "dailyExternalEmployeeId", name: "Mitarbeiter ID", newName: "Personalnummer", checked: true},
          {key: "dailyLohnart", name: "Lohnart", newName: "Art", checked: true},
          {key: "dailyFrom", name: "Startdatum", newName: "von", checked: true},
          {key: "dailyTo", name: "Endedatum", newName: "bis", checked: true},
          {key: "dailyPayoutValue", name: "Wert", newName: "Verwaltung", checked: true},
          {key: "dailyPayoutHoursValue", name: "Wert Bezahlt", newName: "Bezahlt", checked: true},
          {key: "dailyProcessingFlag", name: "Verarbeitungs-KZ", newName: "Verarbeitungs-KZ", checked: true},
          {key: "dailyContentName", name: "Inhalt Bezeichnung", newName: "Inhalt", checked: true},
          {key: "dailyEmployeeName", name: "Mitarbeiter Name", newName: "Name", checked: true},
          {key: "storeId", name: "storeId", newName: "storeId", checked: true},
          {key: "remoteId", name: "remoteId", newName: "remoteId", checked: true}
      ];
      payload.exportSettings.availableFieldsAdditional = [
          {
              "key": "headerAdditional",
              "name": "Listenaufbau",
              "item": {
                  "key": 0,
                  "label": "Spaltenbezeichnungen nicht andrucken"
              },
              "checked": true
          },
          {
              "key": "dailyPayoutValueAdditional",
              "name": "Wert",
              "item": {
                  "key": 1,
                  "label": "Wert in Tagen",
                  "selected": true
              },
              "checked": true
          },
          {
              "key": "dailyPayoutHoursValueAdditional",
              "name": "Wert Bezahlt",
              "item": {
                  "key": 0,
                  "label": "Wert in Stunden"
              },
              "checked": true
          },
          {
              "key": "dailyProcessingFlagAdditional",
              "name": "Verarbeitungs-KZ",
              "item": {
                  "key": 0,
                  "label": "Abwesenheiten neu anlegen",
                  "selected": true
              },
              "checked": true
          }
      ];
      return payload;
    },
    getCsvExportFromBackend(type, payload) {
      let view = this;

      let baseUrl = '';
      if (type == 'monthly') {
        baseUrl = '/api/sec/bmd/exports/MFS/create';
      } else if (type == 'individual-wagetypes') {
        baseUrl = '/api/sec/bmd/exports/IWT/create';
      } else if (type == 'daily') {
        baseUrl = '/api/sec/bmd/exports/AS/create';
      } else if (type == 'pp') {
        baseUrl = '/api/sec/bmd/exports/PP/create';
      } else {
        console.error('Unknown export type');
        return Promise.reject('Unknown export type');
      }

      let url = baseUrl;
      url = this.$helpers.appendParam("companyId", this.$store.state.companyId, url, baseUrl);

      return this.axios({
        method: 'post',
        data: payload,
        headers: {
          'AUTHORIZATION': "Bearer "+this.$store.state.jwt
        },
        url: url
      });

    },

  },
}
</script>

<style scoped>
/* remove max-height from sub component: 100vh would cut off long lists */
>>>.config-entry>.body.entry-expanded {
  max-height: 100% !important;
}
</style>
